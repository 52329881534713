import React, { useState } from 'react';
import './App.css';

function App() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [targetFormat, setTargetFormat] = useState('png');
  const [downloadUrls, setDownloadUrls] = useState([]);

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  const handleFormatChange = (e) => {
    setTargetFormat(e.target.value);
  };

  const handleConvert = async () => {
    if (selectedFiles.length === 0) {
      alert('请先选择至少一个文件');
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('images', file);
    });
    formData.append('format', targetFormat);

    try {
      const response = await fetch('https://imageconvert.playlearn.xyz/api/convert', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        setDownloadUrls(data.urls); // 假设返回的 urls 是一个数组
      } else {
        alert(data.message || '转换失败');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('转换出错');
    }
  };

  return (
    <div className="App">
      <h1>图片格式转化工具</h1>
      <input type="file" multiple onChange={handleFileChange} />
      <select value={targetFormat} onChange={handleFormatChange}>
        <option value="png">PNG</option>
        <option value="jpeg">JPEG</option>
        <option value="gif">GIF</option>
        <option value="webp">WEBP</option>
        <option value="tiff">TIFF</option>
      </select>
      <button onClick={handleConvert}>转换</button>
      {downloadUrls.length > 0 && (
        <div>
          <h2>转换结果</h2>
          {downloadUrls.map((url, index) => (
            <div key={index}>
              <a href={url} download={`converted_${index + 1}.${targetFormat}`}>下载转换后的图片 {index + 1}</a>
            </div>
          ))}
          <div>
            <a href={downloadUrls[0].replace(/[^/]+$/, 'converted_images.zip')} download>下载所有转换后的图片</a>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
